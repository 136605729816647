import React, { ChangeEvent, FormEvent, useState } from 'react';
import MainLayout from '../layout/MainLayout';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Input from '../components/Input';
import axios, { AxiosError } from 'axios';
import Alert from '../components/Alert';
import { Helmet } from 'react-helmet';

const Contact: React.FC = () => {
  const { i18n } = useTranslation();
  const [formData, setFormData] = useState({
    to: 'contact@opalerental.fr',
    last_name: '',
    first_name: '',
    from: '',
    subject: '',
    text: '',
  });

  const isFormDataEmpty = 
    !formData.last_name || 
    !formData.first_name || 
    !formData.from || 
    !formData.subject || 
    !formData.text;

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error'>('success');

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMail(e);
  };

  const sendMail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    formData.text = `Prise de contact : \n
                     De: ${formData.first_name} ${formData.last_name} \n
                     Email : ${formData.from} \n
                     ${formData.text}`;

    try {
      const response = await axios.post('https://intranetapi.rdvtransports.com/public/send/mail', formData);
      setAlertMessage(t('message_sent'));
      setAlertType('success');
      setAlertVisible(true);
      setFormData({
        to: 'contact@opalerental.fr',
        last_name: '',
        first_name: '',
        from: '',
        subject: '',
        text: '',
      });
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axios.isAxiosError(axiosError)) {
        console.error('Erreur Axios:', axiosError.response?.data);
        console.error('Statut:', axiosError.response?.status);
        console.error('En-têtes:', axiosError.response?.headers);
      } else if (error instanceof Error) {
        console.error('Erreur:', error.message);
      } else {
        console.error('Erreur inconnue:', error);
      }
      setAlertMessage(t('message_error'));
      setAlertType('error');
      setAlertVisible(true);
    }
  };

  return (
    <MainLayout>      
      <Helmet>
        <title>{t('meta_title_contact')}</title>
        <meta name="description" content={t('meta_description_contact')} />
      </Helmet>
      {alertVisible && (
        <Alert 
          message={alertMessage} 
          type={alertType} 
          onClose={() => setAlertVisible(false)} 
        />
      )}
      <div className="container mx-auto p-6 lg:w-2/3 mx-auto">
        <h1 className="text-center text-3xl font-roboto font-bold text-secondary mb-6">{t('contact_us')}</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col sm:flex-row justify-between">
            <div className="w-full sm:w-[48%] mb-4 sm:mb-0">
              <Input
                type="text"
                label='last_name'
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
                placeholder="placeholder_last_name"
              />
            </div>
            <div className="w-full sm:w-[48%]">
              <Input
                type="text"
                label='first_name'
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
                placeholder="placeholder_first_name"
              />
            </div>
          </div>
          <div>
            <Input
              type="email"
              label='email'
              name="from"
              value={formData.from}
              onChange={handleChange}
              required
              placeholder="placeholder_email"
            />
          </div>
          <div>
            <Input
              type="text"
              label='object'
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              placeholder="placeholder_object"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-quicksand font-thin">{t("message")}</label>
            <textarea
              name="text"
              value={formData.text}
              onChange={handleChange}
              required
              placeholder={t('placeholder_message')}
              className="w-full p-2 border-2 border-primary rounded-xl bg-input focus:outline-none focus:bg-focused-input h-32 placeholder-white"
            />
          </div>
          <button
            type="submit"
            className={`w-1/3 bg-primary text-white font-roboto font-bold p-2 rounded-lg hover:bg-teal-600 transition duration-200 ${isFormDataEmpty ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={isFormDataEmpty}
          >
            {t('send')}
          </button>
        </form>
      </div>
    </MainLayout>
  );
};

export default Contact;
