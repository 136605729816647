import { t } from 'i18next';
import React from 'react';

interface ButtonProps {
  text: string;
  onClick: () => void;
  buttonColor?: string;
  textColor?: string;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  buttonColor = 'bg-primary',
  textColor = 'text-white',
  className = '',
}) => {
  return (
    <button
      onClick={onClick}
      className={`${buttonColor} ${textColor} py-4 px-8 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${buttonColor.replace('bg-', '')} ${className} hover:bg-opacity-90`}
    >
      {t(text)}
    </button>
  );
};

export default Button;
