import React from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import equipmentImg from '../assets/background_home.webp';
import workshopImg from '../assets/workshop.webp';
import Card from '../components/Card';

const ParkFirstSection: React.FC = () => {
  const { i18n } = useTranslation();
  
  return (
    <section className="container mx-auto p-6 lg:w-2/3 text-center">
        <h2 className="text-4xl font-roboto font-bold text-red-600 mb-10">{t('our_park')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto text-left">
            <Card title="equipment" content="equipment_summary" img={equipmentImg} />
            <Card title="workshop" content="workshop_summary" img={workshopImg} />
        </div>
        <p className="text-secondary text-2xl font-quicksand font-bold mt-12 lg:mx-16">
            {t('our_park_pickup_line')}
        </p>
    </section>
  );
};

export default ParkFirstSection;
