import { t } from 'i18next';
import React from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaLinkedin } from 'react-icons/fa';

const Footer: React.FC = () => {
    return (
        <footer className="bg-primary text-white py-8 text-center">

          <div className='p-6 lg:w-2/3 mx-auto'>
            <h1 className='text-4xl font-roboto font-bold pb-4'>{t('a_project?')}</h1>
            <p className='text-2xl font-roboto font-bold pb-4'>{t('footer_text')}</p>
            
            <hr className="w-1/6 h-1 bg-secondary mx-auto my-4 border-none" />

            <div className="flex flex-col lg:flex-row justify-between items-center py-4">
              <div className="flex items-center space-x-2">
                <FaEnvelope className="text-secondary text-2xl" />
                <span className="text-white underline font-quicksand font-thin">
                  <a href="mailto:contact@opalerental.fr">
                    contact@opalerental.fr
                  </a>
                </span>
              </div>

              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2 space-y-4 lg:space-y-0 font-quicksand font-thin">
                  <FaMapMarkerAlt className="text-secondary text-2xl" />
                  <span className="text-white">200 rue Auguste Bartholdi, 62730 - Marck, France</span>
                </div>
              </div>

              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-2 space-y-4 lg:space-y-0 font-quicksand font-thin">
                  <FaPhoneAlt className="text-secondary text-2xl" />
                  <span className="text-white">+33 6 09 79 63 33</span>
                </div>
              </div>
            </div>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3358.3958459957767!2d1.9327112773086332!3d50.944302371690874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47dc41c22f8b67f9%3A0xf87223bbef3426fa!2sOpale%20Rental!5e1!3m2!1sfr!2sfr!4v1726732829775!5m2!1sfr!2sfr"
              width="600"
              height="450"
              style={{ border: 0 }}
              className='w-full'
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className='flex justify-center'>
            <a href='https://www.linkedin.com/company/opale-rental/' target='blank'>

            <FaLinkedin className='text-blue-600 text-4xl'/>
            </a>
          </div>
        </footer>
      );
};

export default Footer;
