import React, { ChangeEvent, FormEvent, useState } from 'react';
import MainLayout from '../layout/MainLayout';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Select from '../components/Select';
import Input from '../components/Input';
import ConsentCheckbox from '../components/ConsentCheckbox';
import axios, { AxiosError } from 'axios';
import Alert from '../components/Alert';
import { Helmet } from 'react-helmet';

const QuoteRequest: React.FC = () => {
  const { i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState('');
  const [formData, setFormData] = useState({
    to: 'contact@opalerental.fr',
    from: 'no-reply@opalerental.fr',
    subject: 'Demande de devis',
    society: '',
    coordinates: '',
    equipment: '',
    duration: '',
    text: '',
  });
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState<'success' | 'error'>('success');

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(e.target.value);
    setFormData({ ...formData, equipment: e.target.value });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleConsentChange = () => {
    setIsConsentChecked(!isConsentChecked);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMail(e);
  };

  const options = [
    { value: 'Tracteur', label: 'tractor' },
    { value: 'Semi-Remorque', label: 'semi-trailer' },
    { value: 'Porteur', label: 'carrier' },
  ];

  const sendMail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    formData.text = `Société: ${formData.society} \n
                     Coordonnées : ${formData.coordinates} \n
                     Matériel : ${formData.equipment} \n
                     Durée : ${formData.duration} \n
                     ${formData.text}`;

    try {
      const response = await axios.post('https://intranetapi.rdvtransports.com/public/send/mail', formData);
      setAlertMessage(t('message_sent'));
      setAlertType('error');
      setAlertVisible(true);
      setFormData({
        to: 'contact@opalerental.fr',
        from: 'no-reply@opalerental.fr',
        subject: 'Demande de devis',
        society: '',
        coordinates: '',
        equipment: '',
        duration: '',
        text: '',
      });
      setSelectedValue('');
      setIsConsentChecked(false);
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axios.isAxiosError(axiosError)) {
        console.error('Erreur Axios:', axiosError.response?.data);
        console.error('Statut:', axiosError.response?.status);
        console.error('En-têtes:', axiosError.response?.headers);
      } else if (error instanceof Error) {
        console.error('Erreur:', error.message);
      } else {
        console.error('Erreur inconnue:', error);
      }
      setAlertMessage(t('message_error'));
      setAlertType('error');
      setAlertVisible(true);
    }
  };

  const isFormDataValid = 
    formData.society &&
    formData.coordinates &&
    formData.equipment &&
    formData.duration &&
    formData.text &&
    isConsentChecked;

  return (
    <MainLayout>      
      <Helmet>
        <title>{t('meta_title_quote_request')}</title>
        <meta name="description" content={t('meta_description_quote_request')} />
      </Helmet>
      {alertVisible && (
        <Alert 
          message={alertMessage} 
          type={alertType} 
          onClose={() => setAlertVisible(false)} 
        />
      )}
      <div className="container mx-auto p-6 lg:w-2/3">
        <h1 className="text-center text-3xl font-roboto font-bold text-secondary mb-6">{t('quote_request_title')}</h1>
        <p className='text-center font-quicksand text-secondary mb-6' dangerouslySetInnerHTML={{ __html: t('quote_request_text') }}></p>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex flex-col sm:flex-row justify-between">
            <div className="w-full sm:w-[48%] mb-4 sm:mb-0">
              <Input
                type="text"
                label='society'
                name="society"
                value={formData.society}
                onChange={handleChange}
                required
                placeholder="placeholder_society"
              />
            </div>
            <div className="w-full sm:w-[48%]">
              <Input
                type="text"
                label='coordinates'
                name="coordinates"
                value={formData.coordinates}
                onChange={handleChange}
                required
                placeholder="placeholder_coordinates"
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between">
            <div className="w-full sm:w-[48%] mb-4 sm:mb-0">
              <Select
                label="equipment"
                name="equipment"
                value={selectedValue}
                options={options}
                onChange={handleSelectChange}
                required
              />
            </div>
            <div className="w-full sm:w-[48%]">
              <Input
                type="text"
                label='duration'
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                required
                placeholder="placeholder_duration"
              />
            </div>
          </div>
          <div>
            <label className="block text-gray-700 font-quicksand font-thin">{t("comment")}</label>
            <textarea
              name="text"
              value={formData.text}
              onChange={handleChange}
              required
              placeholder={t('placeholder_comment')}
              className="w-full p-2 border-2 border-primary rounded-xl bg-input focus:outline-none focus:bg-focused-input h-32 placeholder-white"
            />
          </div>

          <ConsentCheckbox checked={isConsentChecked} onChange={handleConsentChange} />

          <button
            type="submit"
            className={`w-1/3 bg-primary text-white font-roboto font-bold p-2 rounded-lg hover:bg-teal-600 transition duration-200 ${!isFormDataValid ? 'opacity-50 cursor-not-allowed' : ''}`}
            disabled={!isFormDataValid}
          >
            {t('send')}
          </button>
        </form>
      </div>
    </MainLayout>
  );
};

export default QuoteRequest;
