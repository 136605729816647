import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaPhoneAlt, FaEnvelope, FaBars } from 'react-icons/fa';
import logo from '../assets/Logo.webp';
import { useTranslation } from 'react-i18next';

const Header: React.FC = () => {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value);
  };

  const getLinkClassName = (path: string) => {
    return location.pathname === path
      ? 'text-red-500 border-b-2 border-red-500'
      : 'hover:text-red-500 cursor-pointer';
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-white shadow-md">
      <div className="flex justify-between items-center p-4 w-full lg:w-2/3 mx-auto">
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="OpaleRental Logo" className="h-16 lg:h-32" />
          </Link>
        </div>

        <div className="lg:flex items-center lg:space-x-8 font-quicksand font-thin">
          <div className="flex items-center space-x-2">
            <FaPhoneAlt className="text-red-600" />
            <span className="text-gray-700">+33 6 09 79 63 33</span>
          </div>

          <div className="flex items-center space-x-2">
            <FaEnvelope className="text-red-600" />
            <span className="text-gray-700 underline">
              <a href="mailto:contact@opalerental.fr">
                contact@opalerental.fr
              </a>
            </span>
          </div>
        </div>
      </div>

      <nav className="bg-primary text-white">
        <div className="flex justify-between items-center p-4 w-full lg:w-2/3 mx-auto">
          <div className="lg:hidden">
            <button onClick={toggleMenu} className="text-white">
              <FaBars size={24} />
            </button>
          </div>

          <ul className={`flex-col lg:flex-row space-y-4 lg:space-y-0 flex lg:space-x-10 py-2 text-lg font-roboto ${isOpen ? 'block' : 'hidden'} lg:flex`}>
            <li className={getLinkClassName('/')}>
              <Link to="/">{t('home')}</Link>
            </li>

            <li className="relative group hidden lg:block">
              <Link to="/park" className={getLinkClassName('/park')}>
                {t('our_park')}
              </Link>
              <ul className="absolute top-10 left-0 bg-primary shadow-md p-2 space-y-2 opacity-0 group-hover:opacity-100 group-hover:block transition-opacity duration-300 z-20">
                <li>
                  <Link to="/park/tractor" className={`block px-4 py-2 hover:opacity-100 ${getLinkClassName('/park')}`}>{t('tractor')}</Link>
                </li>
                <li>
                  <Link to="/park/trailer" className={`block px-4 py-2 hover:opacity-100 ${getLinkClassName('/park')}`}>{t('semi-trailer')}</Link>
                </li>
              </ul>
            </li>

            {isOpen && (
              <>
                <li className={getLinkClassName('/park')}>
                  <Link to="/park">{t('our_park')}</Link>
                </li>

                <li className={getLinkClassName('/park/tractor')}>
                  <Link to="/park/tractor">{t('tractor')}</Link>
                </li>
                <li className={getLinkClassName('/park/trailer')}>
                  <Link to="/park/trailer">{t('semi-trailer')}</Link>
                </li>
              </>
            )}

            <li className={getLinkClassName('/quote/request')}>
              <Link to="/quote/request">{t('quote_request')}</Link>
            </li>
            <li className={getLinkClassName('/contact')}>
              <Link to="/contact">{t('contact')}</Link>
            </li>
          </ul>

          <div className="relative">
            <select
              value={i18n.language}
              onChange={handleLanguageChange}
              className="bg-white text-gray-700 border border-gray-300 rounded-md py-1 px-2 cursor-pointer font-roboto"
            >
              <option value="fr">
                {t('fr')}
              </option>
              <option value="en">
                {t('en')}
              </option>
              <option value="de">
                {t('de')}
              </option>
            </select>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
