import React from 'react';
import MainLayout from '../layout/MainLayout';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FaHandshake, FaWrench, FaMoneyBill, FaTruck, FaBullseye } from 'react-icons/fa';


const WhyOpale: React.FC = () => {
  const { i18n } = useTranslation();
  
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 font-quicksand">
      <div className="text-center">
        <FaHandshake className="text-5xl text-primary mx-auto" />
        <h3 className="text-red-500 font-bold text-xl mt-4">{t('flexibility')}</h3>
        <p className="text-gray-700">{t('flexibility_text')}</p>
      </div>

      <div className="text-center">
        <FaWrench className="text-5xl text-primary mx-auto" />
        <h3 className="text-red-500 font-bold text-xl mt-4">{t('facility_serenity')}</h3>
        <p className="text-gray-700">{t('facility_serenity_text')}</p>
      </div>

      <div className="text-center">
        <FaMoneyBill className="text-5xl text-primary mx-auto" />
        <h3 className="text-red-500 font-bold text-xl mt-4">{t('competitive')}</h3>
        <p className="text-gray-700">{t('competitive_text')}</p>
      </div>

      <div className="text-center">
        <FaTruck className="text-5xl text-primary mx-auto" />
        <h3 className="text-red-500 font-bold text-xl mt-4">{t('disponibility')}</h3>
        <p className="text-gray-700">{t('disponibility_text')}</p>
      </div>

      <div className="text-center">
        <FaBullseye className="text-5xl text-primary mx-auto" />
        <h3 className="text-red-500 font-bold text-xl mt-4">{t('vehicles_adapted')}</h3>
        <p className="text-gray-700">{t('vehicles_adapted_text')}</p>
      </div>
    </div>
    
  );
};

export default WhyOpale;
