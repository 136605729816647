import React from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import tractorIMG from '../assets/background_home.webp';
import trailerIMG from '../assets/trailer.webp';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/Button';

const ParkSecondSection: React.FC = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    return navigate('/contact');
  };
  
  return (
    <section className="bg-tertiary">
        <div className='container mx-auto p-6 lg:w-2/3 text-center'>
            <h1 className='text-3xl font-quicksand font-medium pb-6'>{t('rental_requirements')}</h1>
            <p className='pb-12' dangerouslySetInnerHTML={{ __html: t('rental_requirements_text') }}></p>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto text-left pb-8'>
                <div className='mx-auto'>
                    <h1 className='text-2xl font-roboto font-bold text-center pb-4'>{t('tractor')}s</h1>

                    <Link to="/park/tractor">
                        <img 
                        src={tractorIMG} 
                        alt='tractor' 
                        className='w-64 h-64 object-cover rounded-full hover:shadow-lg hover:scale-105 transition ease-in-out duration-300 hover:cursor-pointer' 
                        />
                    </Link>
                </div>
                <div className='mx-auto'>
                    <h1 className='text-2xl font-roboto font-bold text-center pb-4'>{t('semi-trailer')}s</h1>
                    <Link to="/park/trailer">
                        <img 
                        src={trailerIMG} 
                        alt='tractor' 
                        className='w-64 h-64 object-cover rounded-full hover:shadow-lg hover:scale-105 transition ease-in-out duration-300 hover:cursor-pointer' 
                        />
                    </Link>
                </div>
            </div>

            <Button
                text="more_info_contact"
                onClick={handleClick}
            />
        </div>
    </section>
  );
};

export default ParkSecondSection;
