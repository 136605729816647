import React from 'react';
import MainLayout from '../layout/MainLayout';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import ParkFirstSection from './ParkFirstSection';
import ParkSecondSection from './ParkSecondSection';
import { Helmet } from 'react-helmet';

const Park: React.FC = () => {
  const { i18n } = useTranslation();
  
  return (
    <MainLayout>
      <Helmet>
        <title>{t('meta_title_our_park')}</title>
        <meta name="description" content={t('meta_description_our_park')} />
      </Helmet>
      <ParkFirstSection />
      <ParkSecondSection />
    </MainLayout>
  );
};

export default Park;
