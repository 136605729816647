import React from 'react';

interface ConsentCheckboxProps {
  checked: boolean;
  onChange: () => void; // Fonction pour changer l'état de la case à cocher
}

const ConsentCheckbox: React.FC<ConsentCheckboxProps> = ({ checked, onChange }) => {
  return (
    <div className="flex items-start">
      {/* Custom Checkbox */}
      <label className="flex items-center cursor-pointer">
        <div
          className={`w-5 h-5 rounded-full border-2 flex items-center justify-center mr-4 ${
            checked ? 'bg-primary border-primary' : 'bg-gray-300 border-gray-300'
          }`}
          onClick={onChange} // Appelle la fonction onChange lorsqu'on clique sur la case
        >
          {checked && (
            <div className="w-3 h-3 rounded-full bg-white"></div>
          )}
        </div>
      </label>

      {/* Text */}
      <p className="text-gray-700 text-sm">
        En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de votre
        demande d'informations. Les informations saisies seront confidentielles et uniquement utilisées par Opale Rental
        afin de pouvoir vous recontacter pour la relation commerciale.
      </p>
    </div>
  );
};

export default ConsentCheckbox;
