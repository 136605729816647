import React from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';


const Description: React.FC = () => {
  const { i18n } = useTranslation();
  
  return (
    <div className="flex flex-col xl:flex-row text-center justify-center bg-tertiary">
        <div className="bg-white p-6 shadow-lg w-full xl:w-[25%] flex flex-col justify-center items-center">
            <h2 className="text-primary font-bold font-roboto text-2xl">Opale <span className="text-secondary">Rental</span></h2>
            <p className="text-primary text-lg font-roboto font-thin mt-2">
                {t('opale_needs')}
            </p>
        </div>

        <div className="bg-secondary p-6 shadow-lg w-full xl:w-[25%] flex flex-col justify-center items-center">
            <h2 className="text-white font-roboto font-bold text-3xl uppercase">{t('your_partner')}</h2>
        </div>
    </div>
  );
};

export default Description;
