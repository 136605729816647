import { t } from 'i18next';
import React from 'react';

interface ButtonProps {
    title: string;
    content: string;
    img: string;
}

const Card: React.FC<ButtonProps> = ({
    title,
    content,
    img
}) => {
  return (
    <div className="max-w-sm mx-auto bg-primary rounded-lg shadow-lg overflow-hidden">
        <img
        src={img}
        alt="Equipment"
        className="w-full h-64 object-cover"
        />
        <div className="p-6">
        <h3 className="text-2xl font-roboto font-bold text-black mb-4">{t(title)}</h3>
        <p className="text-black font-quicksand font-thin text-lg">
            {t(content)}
        </p>
        </div>
    </div>
  );
};

export default Card;
