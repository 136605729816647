import React, { useEffect, useState } from 'react';

interface AlertProps {
  message: string;
  type: 'success' | 'error';
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isExiting) {
      const exitTimer = setTimeout(() => {
        onClose();
      }, 300);

      return () => clearTimeout(exitTimer);
    }
  }, [isExiting, onClose]);

  return isVisible ? (
    <div
      className={`p-4 mb-4 text-sm text-green-800 rounded-lg fixed top-5 right-5 z-50 p-6 rounded-md shadow-lg transition-transform duration-300 ease-in-out ${
        type === 'success' ? 'bg-green-50' : 'bg-red-50'
      } ${isExiting ? 'translate-x-full' : 'translate-x-0'}`}
      role="alert"
    >
      <span className="font-medium">{message}</span>
      <button
        onClick={() => setIsExiting(true)}
        className="text-2xl font-bold hover:opacity-75 ml-4"
      >
        &times;
      </button>
    </div>
  ) : null;
};

export default Alert;
