import React from 'react';
import MainLayout from '../layout/MainLayout';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import WhyOpale from './WhyOpale';
import backgroundIMG from '../assets/background_home.webp';
import Description from './Description';
import { Helmet } from 'react-helmet';

const Home: React.FC = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  
  const handleClick = () => {
    return navigate('/park');
  };
  
  return (
    <MainLayout>
      <Helmet>
        <title>{t('meta_title_home')}</title>
        <meta name="description" content={t('meta_description_home')} />
      </Helmet>
      <div className="relative z-10">
        <img
          src={backgroundIMG}
          alt="Camion Opale Rental"
          className="w-full h-[60vh] object-cover"
        />

        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 py-6">
          <div className="text-center">
            <h1 className="text-white text-5xl font-roboto font-bold mb-2">Opale Rental</h1>
            <p className="text-white font-quicksand text-lg">{t('sub_title')}</p>
          </div>
        </div>
      </div>

      < Description />

      <div className='bg-tertiary py-4'>
        <div className='p-6 lg:w-2/3 mx-auto'>
          <FaQuoteLeft className='text-5xl text-primary mb-4' />
          <p className='font-quicksand' dangerouslySetInnerHTML={{ __html: t('home_intro_1') }} />
          <br/>
          <p className='font-quicksand' dangerouslySetInnerHTML={{ __html: t('home_intro_2') }} />
          <FaQuoteRight className='text-5xl text-primary ml-auto mt-4' />

          <h1 className='text-center text-7xl font-roboto font-bold'>{t('why_opale')}</h1>

          <hr className="w-1/6 h-1 bg-secondary mx-auto my-4 border-none" />

          <WhyOpale />

        </div>

        <div className='text-center'>
          <Button
            text="our_park"
            onClick={handleClick}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default Home;
