import { t } from 'i18next';
import React from 'react';

interface InputProps {
  type?: string;
  label: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder?: string;
  className?: string;
  ariaLabel?: string;
}

const Input: React.FC<InputProps> = ({
  type = 'text',
  label,
  name,
  value,
  onChange,
  required = false,
  placeholder = '',
  className = '',
  ariaLabel,
}) => {
  return (
    <>
      <label className="block text-gray-700 font-quicksand font-thin">{t(label)} *</label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        placeholder={t(placeholder)}
        aria-label={ariaLabel}
        className={`w-full p-2 border-2 border-primary rounded-xl bg-input focus:outline-none focus:bg-focused-input placeholder-white ${className}`}
      />
    </>
  );
};

export default Input;
