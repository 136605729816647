import React, { useState } from 'react';
import MainLayout from '../layout/MainLayout';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import Lightbox from 'react-image-lightbox';
import { Gallery , Image } from 'react-grid-gallery';
import "react-image-lightbox/style.css";
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface CustomImage extends Image {
  original: string;
}

const IMAGES: CustomImage[] = [
  {
    src: '/tractor/tractor1.webp',
    original: '/tractor/tractor1.webp',
    width: 320,
    height: 212,
    caption: '',
  },
  {
    src: '/tractor/tractor2.webp',
    original: '/tractor/tractor2.webp',
    width: 320,
    height: 212,
    caption: '',
  },
  {
    src: '/tractor/tractor3.webp',
    original: '/tractor/tractor3.webp',
    width: 180,
    height: 212,
    caption: '',
  },
  {
    src: '/tractor/tractor4.webp',
    original: '/tractor/tractor4.webp',
    width: 320,
    height: 212,
    caption: '',
  },
  {
    src: '/tractor/tractor5.webp',
    original: '/tractor/tractor5.webp',
    width: 320,
    height: 212,
    caption: '',
  },
  {
    src: '/tractor/tractor6.webp',
    original: '/tractor/tractor6.webp',
    width: 320,
    height: 212,
    caption: '',
  },
  {
    src: '/tractor/tractor7.webp',
    original: '/tractor/tractor7.webp',
    width: 240,
    height: 212,
    caption: '',
  }
];

const ParkTractor: React.FC = () => {
  const { i18n } = useTranslation();
  const [index, setIndex] = useState(-1);
  const navigate = useNavigate();

  const currentImage = IMAGES[index];
  const nextIndex = (index + 1) % IMAGES.length;
  const nextImage = IMAGES[nextIndex] || currentImage;
  const prevIndex = (index + IMAGES.length - 1) % IMAGES.length;
  const prevImage = IMAGES[prevIndex] || currentImage;

  const handleClick = (index: number, item: CustomImage) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const handleClickContact = () => {
    return navigate('/contact');
  };
  
  return (
    <MainLayout>
      <Helmet>
        <title>{t('meta_title_tractor')}</title>
        <meta name="description" content={t('meta_description_tractor')} />
      </Helmet>
      <div className="container mx-auto p-6 lg:w-2/3 text-center">
        <h1 className='text-4xl font-bold text-secondary mb-8 font-roboto'>{t('our_tractors')}</h1>
        <p className='text-lg mb-4 font-quicksand' dangerouslySetInnerHTML={{ __html: t('our_tractors_1') }}></p>
        <ul className="list-disc list-inside mb-4 font-quicksand">
          <li className='text-lg' dangerouslySetInnerHTML={{ __html: t('our_tractors_1_option1') }}></li>
          <li className='text-lg' dangerouslySetInnerHTML={{ __html: t('our_tractors_1_option2') }}></li>
        </ul>

        <p className='text-lg mb-4 font-quicksand' dangerouslySetInnerHTML={{ __html: t('our_tractors_2') }}></p>
        
        <div className='mb-8'>
          <Gallery
            images={IMAGES}
            enableImageSelection={false}
            onClick={handleClick}
          />
        </div>

        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.original}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}

        <Button
            text="more_info_contact"
            onClick={handleClickContact}
        />

      </div>
      <div className='bg-tertiary'>
        <div className='container mx-auto p-6 lg:w-2/3 text-center'>
          <h3 className='text-4xl font-quicksand font-thin mb-8' >{t('licence_required')}</h3>
          <p className='text-lg mb-8' dangerouslySetInnerHTML={{ __html: t('licence_required_desc_tractor') }}></p>
        </div>
      </div>
    </MainLayout>
  );
};

export default ParkTractor;
